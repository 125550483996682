.otp-boxes {
    display: flex;
    align-items: center;
    column-gap: 10px;
    justify-content: flex-start;
    width: 100%;
}

.otp-group {
    display: flex;
    width: 100%;
    max-width: 360px;
    column-gap: 10px;
  }
  
.otp-input {
    color: #121212;
    caret-color: #121212;
    background-color: #ccd5e0;
    border: none;
    width: 100%;
    height: 60px;
    border-radius: 5px;
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    line-height: 1;
}

.otp-input:focus {
    box-shadow: inset 0px -2px 0px white;
    outline: none;
  }
